/* global Foundation, jQuery */

(function myobOutdatedBrowserFactory($, Foundation) {
  class MyobOutdatedBrowser extends Foundation.MyobAlert {
    constructor(element, options) {
      const overrideOptions = $.extend({}, MyobOutdatedBrowser.defaults, element.data(), options);

      super(element, overrideOptions);

      Foundation.registerPlugin(this, 'MyobOutdatedBrowser');
    }

    _events() {
      super._events();
    }
  }

  MyobOutdatedBrowser.defaults = {
    animate: false,
    componentClass: 'outdated-browser',
    alertDays: 1,
    alertId: 'outdated-browser',
  };

  Foundation.plugin(MyobOutdatedBrowser, 'MyobOutdatedBrowser');
}(jQuery, Foundation));
