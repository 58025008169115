class Tabs {
  constructor(event, data) {
    this.event = event;
    this.data = data;
  }

  googleFormat() {
    const adobeFormattedData = {};
    switch (this.event) {
      case 'tabChange': {
        adobeFormattedData.event = this.data.eventInfo.eventName;
        adobeFormattedData.tabIndex = this.data.attributes.tabIndex;
        adobeFormattedData.tabLabel = this.data.attributes.tabLabel;
        adobeFormattedData.itemCount = this.data.attributes.numOfItems;

        break;
      }

      default:
        throw Error(`Event ${this.event} is not registered`);
    }
    return adobeFormattedData;
  }
}

export default Tabs;
