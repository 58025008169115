/* global Foundation, jQuery */

import 'foundation-sites/js/foundation.dropdownMenu';
import 'foundation-sites/js/foundation.util.keyboard';
import 'foundation-sites/js/foundation.util.box';
import 'foundation-sites/js/foundation.util.nest';
import 'foundation-sites/js/foundation.dropdown';
import AnalyticsHelper from '../../../assets/js/helpers/analytics/analytics-helper';

(function myobDropdownMenuFactory($, Foundation) {
  class MyobDropdownMenu extends Foundation.DropdownMenu {
    constructor(element, options) {
      super(element, options);

      Foundation.registerPlugin(this, 'MyobDropdownMenu');

      this.analyticsHelper = new AnalyticsHelper();
    }

    _events() {
      super._events();
      // const that = this;

      // _events() {
      //   super._events();

      //   this.$element.on('show.zf.dropdownMenu', function (ele, target) {

      //   });

      //   this.$element.on('hide.zf.dropdownMenu', function (ele, target) {

      //   });
      // }
    }
  }

  Foundation.plugin(MyobDropdownMenu, 'MyobDropdownMenu');
}(jQuery, Foundation));
