/* global Foundation, jQuery, YT */

(function myobVideoYoutubeFactory($, window, Foundation) {
  const youtubePlayers = [];

  class MyobVideoYoutube {
    constructor(element, options) {
      this.videoID = null;

      this.$element = element;
      this.options = $.extend({}, MyobVideoYoutube.defaults, this.$element.data(), options);

      this._init();
      Foundation.registerPlugin(this, 'MyobVideoYoutube');
    }

    _init() {
      const that = this;

      this.addScripts();

      this.videoID = this.$element.data('video-id');
      this.youtubePlayerReady = false;

      window.onYouTubeIframeAPIReady = () => { // eslint-disable-line no-param-reassign
        $('[data-myob-video-youtube]').each((i, e) => {
          const youtubeVideoID = $(e).attr('data-video-id');
          const player = that.createYouTubePlayer(youtubeVideoID);
          youtubePlayers.push(player);
        });
      };

      this._events();
    }

    addScripts = () => {
      if ($('#youtube-script').length === 0) {
        const tag = document.createElement('script');
        tag.type = 'text/javascript';
        tag.id = 'youtube-script';
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }
    };

    createYouTubePlayer(youtubeVideoID) {
      const that = this;
      return new YT.Player(youtubeVideoID, {
        videoId: youtubeVideoID,
        width: '640',
        height: '360',
        playerVars: {
          showinfo: 0,
          autoplay: 0,
          controls: 1,
          html5: 1,
          rel: 0,
          wmode: 'transparent',
        },
        events: {
          onReady: (event) => {
            $(window).trigger('ready.myob.videoyoutube', event);
          },
          onStateChange: (event) => {
            that.$element.trigger('statechange.myob.videoyoutube', event);
          },
        },
      });
    }

    videoSeekTo = () => {
      const videoSeekToString = window.Site.getUrlParameter('video-seek-to');

      if (videoSeekToString) {
        const videoSeekToArray = videoSeekToString.split(',');

        videoSeekToArray.forEach((video) => {
          if (video.indexOf(':') > -1) {
            const seekToVideoID = video.split(':')[0];
            const seekToTime = video.split(':')[1];
            const youtubePlayer = YT.get(seekToVideoID);

            if (youtubePlayer && seekToVideoID && seekToTime) {
              youtubePlayer.cueVideoById(seekToVideoID, seekToTime);
            }
          }
        });
      }
    };

    autoPlay() {
      this.autoPlayVideoID = window.Site.getUrlParameter('video-auto-play');

      if (this.autoPlayVideoID) {
        [this.autoPlayVideoID] = this.autoPlayVideoID.split(',');
        let videoPlayed = false;

        const modalComponent = $(`div[data-video-id=${this.autoPlayVideoID}]`).parents('.modal');
        const youtubePlayer = YT.get(this.autoPlayVideoID);

        if (youtubePlayer) {
          if (modalComponent.length === 0) {
            youtubePlayer.playVideo();
          }

          if (window.Site.getUrlParameter('open-modal')) {
            modalComponent.on('open.zf.reveal', () => {
              if (!videoPlayed) {
                youtubePlayer.playVideo();
                videoPlayed = true;
              }
            });
          }
        }
      }
    }

    destroyPlayer = (videoObj) => {
      const youtubePlayer = YT.get(videoObj.videoID);
      if (youtubePlayer) {
        youtubePlayer.destroy();
      }
    };

    pauseYoutubeVideo(youtubeVideoID) {
      if (YT && typeof YT.get === 'function' && this.youtubePlayerReady) {
        YT.get(youtubeVideoID).pauseVideo();
      }
    }

    _events() {
      const that = this;

      $(window).on('ready.myob.videoyoutube', () => {
        that.youtubePlayerReady = true;
        that.videoSeekTo();
        that.autoPlay();
      });

      this.$element.on('statechange.myob.videoyoutube', (ele, event) => {
        const videoTarget = event.target;
        const videoData = videoTarget.getVideoData();
        const videoID = videoData ? videoData.video_id : '';
        if (event.data === YT.PlayerState.PLAYING) {
          $(window).trigger('playing.myob.video', {
            type: 'youtube',
            videoID,
          });
        }
      });

      $(window).on('playing.myob.video', (event, customData) => {
        if (
          !customData.autoplayedByWaypoint
          && (customData.type !== 'youtube'
            || (customData.type === 'youtube' && customData.videoID !== that.videoID))
        ) {
          that.pauseYoutubeVideo(that.videoID);
        }
      });

      $('[data-myob-modal]').on('open.zf.reveal', () => {
        that.pauseYoutubeVideo(that.videoID);
      });

      $('[data-myob-modal]').on('closed.zf.reveal', function onModalClose() {
        const youtubeVideoId = $(this)
          .find('iframe')
          .attr('id');
        if (youtubeVideoId) {
          that.pauseYoutubeVideo(youtubeVideoId);
        }
      });
    }

    destroy() {
      this.destroyPlayer(this);
      $.removeData(this.$element[0], MyobVideoYoutube);
    }
  }

  Foundation.plugin(MyobVideoYoutube, 'MyobVideoYoutube');
}(jQuery, window, Foundation));
