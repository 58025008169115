/* global Foundation, jQuery */

import Cookies from 'js-cookie';
import AnalyticsHelper from '../../../assets/js/helpers/analytics/analytics-helper';

(function myobNavigationLegacyFactory($, Site, Foundation) {
  class MyobNavigationLegacy {
    constructor(element, options) {
      this.$element = element;
      this.options = $.extend({}, MyobNavigationLegacy.defaults, this.$element.data(), options);
      this.navHoverEventData = null;

      this._init();
      Foundation.registerPlugin(this, 'MyobNavigationLegacy');

      this.analyticsHelper = new AnalyticsHelper();
    }

    _init() {
      const $navToggle = this.$element.find('.navbar-toggle');
      const $dropdownItem = this.$element.find('.dropdown__item');

      $.each($navToggle, (key, value) => {
        const target = $(value).data('target');
        $(target).slideUp(0);
      });

      this._events($navToggle, $dropdownItem);

      const myobExp = Cookies.getJSON('myob_xp');

      if (!myobExp || myobExp.nav === 'old') {
        $('.site-header-navigation--new').hide();
        $('.site-header-navigation--old').show();
      } else if (myobExp.nav === 'new') {
        $('.site-header-navigation--old').hide();
        $('.site-header-navigation--new').show();
      }
    }

    slideUpMenu($trigger) {
      const that = this;

      $.each($trigger, (key, value) => {
        const target = $(value).data('target');
        const $target = $(target);

        $trigger.removeClass('active');

        $target.removeClass('active').slideUp(that.options.slideSpeed, () => {
          $trigger.trigger('up.zf.accordionMenu', [$target]);
        });
      });
    }

    slideDownMenu($trigger, $searchInput) {
      const that = this;
      const target = $trigger.data('target');
      const $target = $(target);

      $trigger.addClass('active');

      $target.addClass('active').slideDown(that.options.slideSpeed, () => {
        $trigger.trigger('down.zf.accordionMenu', [$target]);

        if (target === '#desktop-search') {
          $searchInput.focus();
        }
      });
    }

    _events($navToggle, $dropdownItem) {
      const that = this;
      const $searchReset = this.$element.find('.global-reset-div');
      const $searchInput = this.$element.find('.desktop-search__input');
      const $searchCross = this.$element.find('.icon-cross');

      // query parameter to show old/new nav
      const navExp = Site.getUrlParameter('nav-xp');

      if (navExp && (navExp === 'nav_old' || navExp === 'nav_new')) {
        const navToShow = navExp.split('_')[1];
        Cookies.set(
          'myob_xp',
          { nav: navToShow },
          {
            expires: parseInt(14, 10),
          },
        );
      }

      this.$element.find('ul.navbar--nav').on('mouseleave', () => {
        // Navigated away without clicking on any menu item - reset captured nav hover event data
        if (that.navHoverEventData !== null
          && Foundation.MediaQuery.current !== 'small'
          && Foundation.MediaQuery.current !== 'medium'
          && Foundation.MediaQuery.current !== 'large') {
          that.navHoverEventData = null;
        }
      });

      // Main navigation hover - desktop
      this.$element
        .find(
          'div.navbar-desktop div.navbar--left ul.navbar--nav li.dropdown > a, div.navbar-desktop div.navbar--left ul.navbar--nav li.dropdown > span',
        )
        .on('mouseenter.headerNavigationItem', (event) => {
          if (
            Foundation.MediaQuery.current !== 'small'
            && Foundation.MediaQuery.current !== 'medium'
            && Foundation.MediaQuery.current !== 'large') {
            if (that.navHoverEventData === null) {
              that.navHoverEventData = {
                eventInfo: {
                  eventAction: 'hover',
                  eventName: $(event.target)
                    .text()
                    .trim(),
                  timeStamp: Date.now(),
                  type: 'AEM.navigation',
                },
                attributes: {
                  navType: 'dropdown',
                },
              };
            }
          }
        });

      // Sign in menu hover - desktop
      this.$element
        .find('div.navbar-desktop div.navbar--right li.dropdown')
        .on('mouseenter.headerToolbox', (event) => {
          if (
            Foundation.MediaQuery.current !== 'small'
            && Foundation.MediaQuery.current !== 'medium'
            && Foundation.MediaQuery.current !== 'large'
          ) {
            if (that.navHoverEventData === null) {
              that.navHoverEventData = {
                eventInfo: {
                  eventName: $(event.target)
                    .find('.sign-in-label')
                    .text(),
                  eventAction: 'hover',
                  type: 'AEM.navigation',
                  timeStamp: Date.now(), // unix timestamp
                },
                attributes: {
                  navType: 'dropdown',
                },
              };
            }
          }
        });

      // Main navigation and sign in menu hover - mobile
      $navToggle.on('click', (event) => {
        const target = $(event.currentTarget).data('target');
        const $target = $(target);
        let mobileEvtName;

        that.slideUpMenu($navToggle);
        if ($target.length && $target.is(':hidden')) {
          that.slideDownMenu($(event.currentTarget), $searchInput);
        }

        if (target.includes('menu')) {
          mobileEvtName = 'mobile-menu-button';
        } else if (target.includes('sign-in')) {
          mobileEvtName = 'sign-in-button';
        }

        that.navHoverEventData = {
          eventInfo: {
            eventName: mobileEvtName,
            eventAction: 'hover',
            type: 'AEM.navigation',
            timeStamp: Date.now(), // unix timestamp
          },
          attributes: {
            navType: 'hamburger',
          },
        };
      });

      $dropdownItem.on('click', (event) => {
        event.preventDefault();
        const locationHREF = $(event.currentTarget).attr('href');
        if (locationHREF) {
          window.location.href = locationHREF;
        }
      });

      this.$element.find('ul.navbar--nav li.dropdown a').on('click.headerClick', (event) => {
        const el = $(event.target);
        const href = el.attr('href');

        if (typeof href !== 'undefined' && href !== '' && href.indexOf('javascript') < 0) {
          let navClickType = '';
          let navClickTitle = null;
          const navPathArray = [];
          let navParent = null;
          let parentEl;

          if (
            Foundation.MediaQuery.current === 'small'
            || Foundation.MediaQuery.current === 'medium'
            || Foundation.MediaQuery.current === 'large'
          ) {
            navClickType = 'hamburger'; // mobile

            if (event.target.nextElementSibling) {
              // Don't record click if you are expanding a menu in mobile/tablet
              return;
            }
          } else {
            navClickType = 'dropdown'; // desktop
          }

          if (navClickType === 'dropdown') {
            parentEl = el.parentsUntil('nav.navbar');

            for (let i = parentEl.length - 1; i >= 0; i -= 1) {
              navParent = $(parentEl[i]);
              if (navParent.is('li')) {
                navClickTitle = navParent.find('a span.sign-in-label').first().text().trim();

                if (!navClickTitle) {
                  navClickTitle = navParent.find('a').first().text().trim();
                }
                if (navClickTitle) {
                  navPathArray.push(navClickTitle.replace(/ /g, '-'));
                }
              }

              navClickTitle = null;
              navParent = null;
            }
          } else if (navClickType === 'hamburger') {
            parentEl = el.parentsUntil('nav.navbar');

            for (let i = parentEl.length - 1; i >= 0; i -= 1) {
              navParent = $(parentEl[i]);

              if (navParent.is('li')) {
                navClickTitle = navParent.find('a span.sign-in-label').first().text().trim();
                if (!navClickTitle) {
                  navClickTitle = navParent.find('.dropdown__item').first().text().trim();
                }
                if (navClickTitle) {
                  navPathArray.push(navClickTitle.replace(/ /g, '-'));
                }
              }

              navClickTitle = null;
              navParent = null;
            }
          }

          if (that.navHoverEventData !== null) {
            that.analyticsHelper.record(
              'AEM:navigationLegacy:navigationItemHover',
              that.navHoverEventData,
            );
            that.navHoverEventData = null; // reset captured data after a click event is fired
          }

          that.analyticsHelper.record('AEM:navigationLegacy:navigationItemClick', {
            eventInfo: {
              eventAction: 'click',
              eventName: $(event.target)
                .text()
                .trim(),
              timeStamp: Date.now(), // unix timestamp
              type: 'AEM.navigation',
            },
            attributes: {
              navType: navClickType,
              navPath: navPathArray.join('/'),
              targetUrl: href,
            },
          });
        }
      });

      $searchCross.click(() => {
        $('.global-reset').click();
        $searchReset.addClass('hidden');
        $searchInput.focus();
      });

      $searchInput.keyup(() => {
        if ($searchInput.val()) {
          $searchReset.removeClass('hidden');
        } else {
          $searchReset.addClass('hidden');
        }
      });
    }

    destroy() {
      this.$element.off('mouseover.headerNavigationItem').find('ul.navbar--nav li.dropdown');

      this.$element.off('click.headerNavigationItem').find('ul.navbar--nav li.dropdown a');

      Foundation.unregisterPlugin(this);
    }
  }

  MyobNavigationLegacy.defaults = {
    slideSpeed: 250,
  };

  Foundation.plugin(MyobNavigationLegacy, 'MyobNavigationLegacy');
}(jQuery, window.Site, Foundation));
