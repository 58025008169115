class ProductsHelper {
  constructor() {
    this.MYOBProducts = {
      nz: {
        'EP-BND-NZ': {
          productName: 'Essentials Starter',
          description: '',
          price: {
            unitPriceMonth: 30.00,
            minEmployees: 10,
            currencySymbol: '$',
            GST: '+GST',
          },
          terms: 'Subscription fees are in two parts and billed on the 1st of each month. The $30 monthly fee for up to 10 employees is billed in advance and the usage fees for additional employees are billed in arrears.',
          trialURL: 'https://essentials.myob.co.nz/LA.CO.NZ/public.htm#registration?product-id=13&product-info=U2F2ZSB0aW1lIG9uIHBheSBkYXkgYW5kIHN0YXkgY29tcGxpYW50IHdpdGggZWFzeSBvbmxpbmUgcGF5cm9sbDxicj5UcnkgZnJlZSBmb3IgMzAgZGF5cyAmbmRhc2g7IG5vIGNyZWRpdCBjYXJkIHJlcXVpcmVk',
          trialCTA: 'Try FREE for 30 days',
          purchaseURL: 'http://www.myob.co.nz/buy/nz/essentials-payroll',
          purchaseCTA: 'Buy now',
        },
      },
    };
  }

  fetchProduct(country, product) {
    const that = this;
    return new Promise((resolve, reject) => {
      if (country && product && that.MYOBProducts[country] && that.MYOBProducts[country][product]) {
        resolve(that.MYOBProducts[country][product]);
      } else {
        reject(Error('No product found'));
      }
    });
  }
}

export default ProductsHelper;
