/* global Foundation, jQuery */

import AutoComplete from 'js-autocomplete';

/**
 * This plugin contains the logic for the autocomplete suggestions
 * on the text autocomplete component. The data for the suggestions to
 * work needs to be in a specific format.
 */

(function myobAutocomplete($, Foundation) {
  class MyobAutocomplete {
    constructor(element, options) {
      this.$element = element;
      this.options = $.extend({}, MyobAutocomplete.defaults, this.$element.data(), options);

      this._init();
      Foundation.registerPlugin(this, 'MyobAutocomplete');
    }

    _init() {
      this.$input = this.$element.find('[data-autocomplete-suggestions]');
      this.$submit = this.$element.find('button[type="submit"]');
      this._events();
    }

    _events() {
      this.autocomplete = new AutoComplete({
        selector: this.$input[0],
        minChars: 0,
        element: this.$element,
        submit: this.$submit,
        // This method populates the suggestions
        source(term, suggest) {
          const choices = JSON.parse(this.selector.getAttribute('data-autocomplete-suggestions'));
          const suggestions = [];
          const searchTerm = term.toLowerCase();
          let suggestion;

          Object.values(choices).forEach((choiceValue) => {
            suggestion = [];
            let titleAdded = false;
            if (`${choiceValue.title}` !== term) {
              if (`${choiceValue.title}`.toLowerCase().includes(searchTerm)) {
                suggestion.push(choiceValue.title);
                suggestion.push(choiceValue.url);
                titleAdded = true;
              }
              if (choiceValue.tags) {
                Object.keys(choiceValue.tags).forEach((tagKey) => {
                  if (`${choiceValue.tags[tagKey]}`.toLowerCase().includes(searchTerm)) {
                    if (titleAdded) {
                      suggestion.push(choiceValue.tags[tagKey]);
                    } else {
                      suggestion.push(choiceValue.title);
                      titleAdded = true;
                      suggestion.push(choiceValue.tags[tagKey]);
                    }
                  }
                });
              }
            }
            if (suggestion.length > 0) {
              suggestions.push(suggestion);
              this.element.attr('action', '');
            }
          });

          suggest(suggestions);
        },
        // This method updates the markup with the suggestion items
        renderItem(item) {
          if (this.submit && this.submit.hasClass('is-disabled')) {
            this.submit.addClass('is-disabled').attr('disabled');
          }
          return `<div class="autocomplete-suggestion" data-title="${item[0]}" data-url="${item[1]}">${item[0]}</div>`;
        },
        // This method updates value of the text box with the value of the selected suggestion
        onSelect(e, term, item) {
          this.selector.value = `${item.getAttribute('data-title')}`;
          if (this.submit) {
            this.submit.removeClass('is-disabled').removeAttr('disabled');
            this.element.attr('action', `${item.getAttribute('data-url')}`);
          }
        },
      });
    }

    destory() {
      this.autocomplete = null;
    }
  }

  MyobAutocomplete.defaults = {};

  Foundation.plugin(MyobAutocomplete, 'MyobAutocomplete');
}(jQuery, Foundation));
