import 'foundation-sites/js/foundation.core';
import 'foundation-sites/js/foundation.equalizer';
import 'foundation-sites/js/foundation.sticky';
import 'foundation-sites/js/foundation.util.mediaQuery';
import 'foundation-sites/js/foundation.util.triggers';

import '../system/core';

import '../helpers/analytics/analytics-helper';

import '../../../components/general/accordion-menu/accordion-menu.vanilla';
import '../../../components/layout/footer/footer.vanilla';
import '../../../components/forms/form/form.vanilla';
import '../../../components/navigation/navigation-blog/navigation-blog.vanilla';
import '../../../utilities/display-toggle/display-toggle.vanilla';

import register from '../registrar';

if (document.readyState !== 'loading') {
  register();
} else {
  document.addEventListener('DOMContentLoaded', () => {
    register();
  });
}
