/* global Foundation, jQuery, Wistia */

import waypointElemActive from '../../../assets/js/foundations/waypoint';

(function myobVideoWistiaFactory($, window, Foundation) {
  class MyobVideoWistia {
    constructor(element, options) {
      this.videoID = null;
      this.$element = element;
      this.options = $.extend({}, MyobVideoWistia.defaults, this.$element.data(), options);

      this._init();
      Foundation.registerPlugin(this, 'MyobVideoWistia');
    }

    _init() {
      this.addScripts();

      this.videoID = this.$element.data('video-id');
      const timeOut = 1000; // the timeout used for video autoplay
      this.videoSeekTo();
      this.autoPlay();

      this.$element.each((index, element) => {
        const $waypoint = $(element).parents('.waypoint');

        if ($waypoint.length !== 0 && $waypoint[0].hasAttribute('data-waypoint-autoplay')) {
          // the gtm container doesn't capture the play events on autoplay, because it hasn't been loaded yet
          // hence a timeout has been added
          window.setTimeout(() => { this.waypointAutoPlay($waypoint, element); }, timeOut);
        }
      });

      this.trackWistiaVideoEvents();

      this._events();
    }

    addScripts = () => {
      if ($('#wistia-script').length === 0) {
        // window._wq is Wistia video handle.
        // The handle will be given as an argument of the callback function.
        window._wq = window._wq || []; // eslint-disable-line no-param-reassign

        const tag = document.createElement('script');
        tag.type = 'text/javascript';
        tag.id = 'wistia-script';
        tag.src = 'https://fast.wistia.com/assets/external/E-v1.js';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }
    };

    videoSeekTo = () => {
      const videoSeekToString = window.Site.getUrlParameter('video-seek-to');

      if (videoSeekToString) {
        const videoSeekToArray = videoSeekToString.split(',');

        videoSeekToArray.forEach((video) => {
          if (video.indexOf(':') > -1) {
            const seekToVideoID = video.split(':')[0];
            const seekToTime = video.split(':')[1];

            if (window._wq) {
              window._wq.push({
                id: seekToVideoID,
                onReady: (videoObject) => {
                  videoObject.time(seekToTime);
                  videoObject.seekedNotPlayed = true; // eslint-disable-line no-param-reassign
                },
              });
            }
          }
        });
      }
    };

    autoPlay() {
      const that = this;

      this.autoPlayVideoID = window.Site.getUrlParameter('video-auto-play');

      if (this.autoPlayVideoID) {
        [this.autoPlayVideoID] = this.autoPlayVideoID.split(',');
        const modalComponent = $(`div[data-video-id=${this.autoPlayVideoID}]`).parents('.modal');
        let videoPlayed = false;
        if (modalComponent.length === 0 && window._wq) {
          window._wq.push({
            id: this.autoPlayVideoID,
            onReady: (video) => {
              video.play();
            },
          });
        }

        if (window.Site.getUrlParameter('open-modal')) {
          modalComponent.on('open.zf.reveal', () => {
            if (!videoPlayed) {
              window._wq.push({
                id: that.autoPlayVideoID,
                onReady: (video) => {
                  video.play();
                  videoPlayed = true;
                },
              });
            }
          });
        }
      }
    }

    waypointAutoPlay($waypoint, element) {
      window._wq.push({
        id: '_all',
        onReady: (video) => {
          if (waypointElemActive(this.$element)) {
            video.play();
            $(element)
              .addClass('video--active')
              .siblings('.progress-indicator')
              .addClass('is-disabled');
          }
        },
      });

      $waypoint.on('waypoint.on', (event) => {
        const $waypointElement = $(event.target).find(this.$element);
        if (waypointElemActive($waypointElement)) {
          window._wq.push({
            id: $waypointElement.data('videoId'),
            onReady: (video) => {
              video.play();
            },
          });
        }
      });
    }

    pauseWistiaVideo = (videoID) => {
      if (Wistia && typeof Wistia.api === 'function') {
        Wistia.api(videoID).pause();
      }
    };

    trackWistiaVideoEvents() {
      const that = this;
      if (window._wq) {
        window._wq.push({
          id: this.videoID,
          onReady: (video) => {
            video.bind('play', () => {
              $(window).trigger('playing.myob.video', {
                type: 'wistia',
                videoID: video.hashedId(),
                autoplayedByWaypoint: that.$element.parents('[data-waypoint-autoplay]').length > 0,
              });
            });
          },
        });
      }
    }

    _events() {
      const that = this;

      $('[data-myob-modal]').on('open.zf.reveal', () => {
        if (that.$element.parents('[data-waypoint-autoplay]').length === 0) {
          that.pauseWistiaVideo(that.videoID);
        }
      });

      $(window).on('playing.myob.video', (event, customData) => {
        if (
          that.$element.parents('[data-waypoint-autoplay]').length === 0
          && !customData.autoplayedByWaypoint
          && (customData.type !== 'wistia'
            || (customData.type === 'wistia' && customData.videoID !== that.videoID))
        ) {
          that.pauseWistiaVideo(that.videoID);
        }
      });
    }

    destroy() {
      $.removeData(this.$element[0], MyobVideoWistia);
    }
  }

  Foundation.plugin(MyobVideoWistia, 'MyobVideoWistia');
}(jQuery, window, Foundation));
