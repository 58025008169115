class NavigationInPage {
  constructor(event, data) {
    this.event = event;
    this.data = data;
  }

  googleFormat() {
    const googleFormattedData = {};
    switch (this.event) {
      case 'navigationItemClick':
        googleFormattedData.event = this.data.eventInfo.eventAction;
        googleFormattedData.itemCount = this.data.attributes.numOfItems;
        googleFormattedData.subNavInPageUrl = this.data.attributes.targetUrl;
        googleFormattedData.subNavItemIndex = this.data.attributes.subNavItemIndex;
        googleFormattedData.subNavItemLabel = this.data.eventInfo.eventName;
        googleFormattedData.subNavItemUrl = this.data.attributes.targetUrl;
        googleFormattedData.subNavPageUrl = this.data.attributes.targetUrl;
        break;

      default:
        throw Error(`Event ${this.event} is not registered`);
    }
    return googleFormattedData;
  }
}

export default NavigationInPage;
