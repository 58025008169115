/* global Foundation, jQuery */

import AnalyticsHelper from '../../../assets/js/helpers/analytics/analytics-helper';

(function myobProductCardFactory($, window, Foundation) {
  class MyobProductCard {
    constructor(element, options) {
      this.$element = element;
      this.options = $.extend({}, MyobProductCard.defaults, this.$element.data(), options);

      this._init();
      Foundation.registerPlugin(this, 'MyobProductCard');
    }

    _init() {
      this.analyticsHelper = new AnalyticsHelper();
      this._events();
    }

    _events() {
      const $ctaBtn = this.$element.find('.card__btns.btn-group a');

      $ctaBtn.each((index, element) => {
        let eventAction;

        if ($(element).hasClass('btn--try')) {
          eventAction = 'productCardTrialClick';
        } else if ($(element).hasClass('btn--buy')) {
          eventAction = 'productCardBuyClick';
        }

        if (eventAction) {
          $(element).on('click', (event) => {
            this.analyticsHelper.record(`AEM:productCard:${eventAction}`, {
              eventInfo: {
                eventName: `${eventAction}`,
              },
              attributes: {
                componentClass: this.options.componentClass,
                currencyLabel: this.$element
                  .find('.price__currency')
                  .text()
                  .trim(),
                paymentPeriodLabel: this.$element
                  .find('.price__info')
                  .text()
                  .trim(),
                priceLabel: this.$element
                  .find('.price__amount')
                  .text()
                  .trim(),
                pricingBoxBtnLabel: $(event.target)
                  .text()
                  .trim(),
                pricingBoxBtnUrl: $(event.target).attr('href'),
                productLabel: this.$element
                  .find('.card__head h3')
                  .text()
                  .trim(),
                _sourceElement: event.target,
              },
            });
          });
        }
      });
    }
  }

  MyobProductCard.defaults = {
    componentClass: 'productCard',
  };

  Foundation.plugin(MyobProductCard, 'MyobProductCard');
}(jQuery, window, Foundation));
