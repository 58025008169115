/* global Foundation, jQuery */

import 'foundation-sites/js/foundation.accordionMenu';
import 'foundation-sites/js/foundation.util.keyboard';
import 'foundation-sites/js/foundation.util.motion';
import 'foundation-sites/js/foundation.util.nest';
import AnalyticsHelper from '../../../assets/js/helpers/analytics/analytics-helper';

(function myobAccordionMenuFactory($, Foundation) {
  class MyobAccordionMenu extends Foundation.AccordionMenu {
    constructor(element, options) {
      const overrideOptions = $.extend({}, MyobAccordionMenu.defaults, element.data(), options);
      super(element, overrideOptions);

      Foundation.registerPlugin(this, 'MyobAccordionMenu');

      this.analyticsHelper = new AnalyticsHelper();
    }

    _events() {
      super._events();

      // this.$element.on('down.zf.accordionMenu', (ele, target) => {
      //   console.log('down');
      // });
      //
      // this.$element.on('up.zf.accordionMenu', (ele, target) => {
      //   console.log('up');
      // });
    }
  }

  Foundation.plugin(MyobAccordionMenu, 'MyobAccordionMenu');
}(jQuery, Foundation));
