import { registerPluginNames } from '../../../assets/js/registrar';

const addEventListeners = (elementData) => {
  elementData.forEach((element) => {
    if (element.countrySelect) {
      element.countrySelect.addEventListener('change', (event) => {
        const link = event.target.value;
        if (link) window.location.href = link;
      });
    }
  });
};

const init = (elements) => {
  const elementData = Array.from(elements).map(element => ({
    countrySelect: element.querySelector('.select-country select'),
  }));

  addEventListeners(elementData);
};
export default init;

registerPluginNames(init, 'footer-legacy');
