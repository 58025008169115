import * as Fuse from 'fuse.js';
import { show, hide, isActive } from '../helpers/state-helper';


/**
 * Gets all searchable elements
 * @return {Object[]} Items with name, tags and groupings
 */
const getDOMSearchItems = () => Array.from(
  document.querySelectorAll('[data-search-title]')
).map((elem, elemId) => {
  const {
    searchGroup: group,
    searchSubgroup: subgroup,
    searchTitle: title,
    searchTags: tags,
  } = elem.dataset;
  return {
    elem,
    id: elemId,
    title,
    tags: (tags || '').split(','),
    group,
    subgroup,
  };
});

/**
 * Generates a handler function used when a user types in the search
 * Searches provided items and hides/shows the resultant items and their groups.
 * @param  {Object[]} searchItems Items to search
 * @param  {Object} groups {Group identifier: Group toggle element}
 * @param  {Object} subgroups {Subgroup identifier: Subgroup toggle element}
 */
const handleSearchInputChange = (searchItems, groups, subgroups) => (event) => {
  const searchTerm = event.target.value;
  let results = [];
  if (searchTerm) {
    const options = {
      location: 0,
      threshold: 0.2,
    };
    const search = new Fuse(
      searchItems,
      {
        ...options,
        keys: [
          'title',
          'tags',
        ]
      }
    );
    results = search.search(searchTerm);

    // Get the groups with no results in first
    Object.keys(groups).filter(
      groupKey => !results.find(({ group }) => group === groupKey)
    ).forEach((groupKey) => {
      const { trigger, parent } = groups[groupKey];
      const searchItemsInGroup = searchItems.filter(
        ({ group }) => group === groupKey
      );
      searchItemsInGroup.forEach(({ elem }) => show(elem));
      if ((isActive(trigger))) trigger.click();
      hide(parent);
    });

    // Go through items and show/hide if it is/isn't in results

    const groupsToShow = {};
    const subgroupsToShow = {};

    searchItems.forEach(({
      elem, id, group, subgroup
    }) => {
      if (results.find(({ id: resultId }) => id === resultId)) {
        show(elem);
        if (group) groupsToShow[group] = true;
        if (subgroup) subgroupsToShow[subgroup] = true;
      } else {
        hide(elem);
      }
    });

    // Show/hide group levels

    Object.keys(groups).forEach((groupKey) => {
      const showGroup = groupKey in groupsToShow;
      const { trigger, parent } = groups[groupKey];
      if (showGroup) {
        if ((!isActive(trigger))) trigger.click();
        show(parent);
      } else {
        if ((isActive(trigger))) trigger.click();
        hide(parent);
        searchItems.filter(
          ({ group }) => group === groupKey
        ).forEach(({ elem }) => show(elem));
      }
    });

    // Show/hide subgroup levels

    Object.keys(subgroups).forEach((subgroupKey) => {
      const showSubgroup = subgroupKey in subgroupsToShow;
      const { trigger, parent } = subgroups[subgroupKey];
      if (showSubgroup) {
        if ((!isActive(trigger))) trigger.click();
        show(parent);
      } else {
        if ((isActive(trigger))) trigger.click();
        hide(parent);
        searchItems.filter(
          ({ subgroup }) => subgroup === subgroupKey
        ).forEach(({ elem }) => show(elem));
      }
    });
  } else {
    // Collapse all menus, but show everything
    searchItems.forEach(({ elem, group, subgroup }) => {
      show(elem);
      if (group) {
        const { trigger, parent } = groups[group];
        if (isActive(trigger)) trigger.click();
        show(parent);
      }
      if (subgroup) {
        const { trigger, parent } = subgroups[subgroup];
        if (isActive(trigger)) trigger.click();
        show(parent);
      }
    });
  }
};

/**
 * Attaches event listener to rendered search input
 */
const registerSearch = () => {
  const searchInput = document.getElementById('astridd-search');
  if (searchInput) {
    searchInput.focus();
    const searchItems = getDOMSearchItems();
    const groups = {};
    const subgroups = {};
    searchItems.forEach(({ group, subgroup }) => {
      if (group) {
        groups[group] = {
          trigger: document.querySelector(`[data-search-group-trigger=${group}]`),
          parent: document.querySelector(`[data-search-group-id=${group}]`),
        };
      }
      if (subgroup) {
        subgroups[subgroup] = {
          trigger: document.querySelector(`[data-search-subgroup-trigger=${subgroup}]`),
          parent: document.querySelector(`[data-search-subgroup-id=${subgroup}]`),
        };
      }
    });
    searchInput.addEventListener(
      'input',
      handleSearchInputChange(searchItems, groups, subgroups),
      false
    );
  }
};

if (document.readyState !== 'loading') {
  registerSearch();
} else {
  document.addEventListener('DOMContentLoaded', () => {
    registerSearch();
  });
}
