import Accordion from './accordion';
import Alert from './alert';
import FooterLegacy from './footer-legacy';
import Form from './form';
import Modal from './modal';
import NavigationSub from './navigation-in-page';
import PricingCalculator from './pricing-calculator';
import ProductCard from './product-card';
import Tabs from './tabs';

class AEMBaseHandler {
  constructor(component, event, data) {
    this.component = component;
    this.event = event;
    this.data = data;
  }

  translate() {
    let translatedDataObject = {};
    switch (this.component) {
      case 'accordion':
      {
        const accordion = new Accordion(this.event, this.data);
        translatedDataObject = {
          google: accordion.googleFormat(),
        };
        break;
      }

      case 'alert':
      {
        const alert = new Alert(this.event, this.data);
        translatedDataObject = {
          google: alert.googleFormat(),
        };
        break;
      }

      case 'footerLegacy':
      {
        const footerLegacy = new FooterLegacy(this.event, this.data);
        translatedDataObject = {
          google: footerLegacy.googleFormat(),
        };
        break;
      }

      case 'form':
      {
        const form = new Form(this.event, this.data);
        translatedDataObject = {
          google: form.googleFormat(),
        };
        break;
      }

      case 'modal':
      {
        const modal = new Modal(this.event, this.data);
        translatedDataObject = {
          google: modal.googleFormat(),
        };
        break;
      }

      case 'navigationSub':
      {
        const navigationSub = new NavigationSub(this.event, this.data);
        translatedDataObject = {
          google: navigationSub.googleFormat(),
        };
        break;
      }

      case 'pricingCalculator':
      {
        const pricingCalculator = new PricingCalculator(this.event, this.data);
        translatedDataObject = {
          google: pricingCalculator.googleFormat(),
        };
        break;
      }

      case 'productCard':
      {
        const productCard = new ProductCard(this.event, this.data);
        translatedDataObject = {
          google: productCard.googleFormat(),
        };
        break;
      }

      case 'tabs':
      {
        const tabs = new Tabs(this.event, this.data);
        translatedDataObject = {
          google: tabs.googleFormat(),
        };
        break;
      }

      default:
        throw Error(`Component ${this.component} is not registered`);
    }
    return translatedDataObject;
  }
}

export default AEMBaseHandler;
