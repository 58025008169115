import 'foundation-sites/js/foundation.core';
import 'foundation-sites/js/foundation.equalizer';
import 'foundation-sites/js/foundation.util.mediaQuery';
import 'foundation-sites/js/foundation.magellan';

import '../system/core';

import '../helpers/products-helper';
import '../helpers/analytics/analytics-helper';

import '../foundations/waypoint';

import '../../../components/general/accordion/accordion.vanilla';
import '../../../components/general/accordion-menu/accordion-menu.vanilla';
import '../../../components/general/alert/alert.vanilla';
import '../../../components/general/browser-upgrade/browser-upgrade.vanilla';
import '../../../components/general/card/card.vanilla';
import '../../../components/general/card/card.animation';
import '../../../components/general/dropdown-menu/dropdown-menu.vanilla';
import '../../../utilities/filter/filter.vanilla';
import '../../../components/layout/footer/footer.vanilla';
import '../../../components/forms/form/form.vanilla';
import '../../../components/media/image/image.vanilla';
import '../../../components/layout/modal/modal.vanilla';
import '../../../components/navigation/navigation/navigation.vanilla';
import '../../../components/navigation/navigation-legacy/navigation-legacy.vanilla';
import '../../../components/navigation/sub-navigation/sub-navigation.vanilla';
import '../../../components/product-displays/pricing-calculator/pricing-calculator.vanilla';
import '../../../components/general/tabs/tabs.vanilla';
import '../../../components/general/typed-text/typed-text.vanilla';
import '../../../components/media/video/video-wistia.vanilla';
import '../../../components/media/video/video-youtube.vanilla';
import '../../../components/forms/autocomplete-input/autocomplete-input.vanilla';
import '../../../utilities/tooltip/tooltip.vanilla';
import '../../../components/general/smart-call-to-action-button/smart-call-to-action-button.vanilla';
import '../../../utilities/display-toggle/display-toggle.vanilla';

import register from '../registrar';

if (document.readyState !== 'loading') {
  register();
} else {
  document.addEventListener('DOMContentLoaded', () => {
    register();
  });
}
