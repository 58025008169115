/* Purpose: To help add classes to an element to set its visibility or active state */
import { addAnimation, removeAnimation } from './animations-helper';

const ACTIVE_CLASS = 'is-active';
const DISPLAY_CLASS = 'block';
const NO_DISPLAY_CLASS = 'no-display';

/**
 * Add active class to provided element
 * @param {HTMLElement} element
 */
const addActiveState = (element) => {
  element.classList.add(ACTIVE_CLASS);
};

/**
 * Remove active class from provided element
 * @param {HTMLElement} element
 */
const removeActiveState = (element) => {
  element.classList.remove(ACTIVE_CLASS);
};

/**
 * Check if provided element contains active class
 * @param  {HTMLElement}  element
 * @return {Boolean}         True if active class is present
 */
const isActive = element => element.classList.contains(ACTIVE_CLASS);

/**
 * Check if provided element is hidden
 * @param  {HTMLElement}  element
 * @return {Boolean}         True if no-display class is present
 */
const isHidden = element => element.classList.contains(NO_DISPLAY_CLASS);

/**
 * Add no-display class to provided element
 * @param {HTMLElement} element
 */
const hide = (element) => {
  element.classList.add(NO_DISPLAY_CLASS);
  element.classList.remove(DISPLAY_CLASS);
};

/**
 * Remove no-display class from provided element
 * @param {HTMLElement} element
 */
const show = (element) => {
  element.classList.remove(NO_DISPLAY_CLASS);
  element.classList.add(DISPLAY_CLASS);
};

/**
 * From the given action, ascertains if the given item should be shown or hidden
 * If animationIn or animationOut are provided, we use the animation helpers
 * to ensure a smooth visual experience for the user,
 * otherwise we just toggle classes to show and hide the element.
 * @param  {HTMLElement} item          Item to interact with
 * @param  {string} animationIn  Name of animation for elements showing
 * @param  {string} animationOut Name of animation for elements hiding
 * @param  {string} action         Either 'hide' or 'show'
 */
const setItemState = (item, animationIn, animationOut, action) => {
  const classAnimIn = animationIn ? `anim--${animationIn}` : null;
  const classAnimOut = animationOut ? `anim--${animationOut}` : null;
  const animate = classAnimIn || classAnimOut;
  if (action === 'hide') {
    if (!isHidden(item)) {
      if (animate) {
        removeAnimation(item, classAnimIn);
        addAnimation(
          item, classAnimOut, null,
          (elem, anim) => { hide(elem); removeAnimation(elem, anim); }
        );
      } else {
        hide(item);
      }
    }
  } else if (isHidden(item)) {
    if (animate) {
      addAnimation(item, classAnimIn, show, removeAnimation);
      removeAnimation(item, classAnimOut);
    } else {
      show(item);
    }
  }
};


export {
  ACTIVE_CLASS, DISPLAY_CLASS, NO_DISPLAY_CLASS,
  addActiveState, removeActiveState, isActive,
  isHidden, hide, show,
  setItemState,
};
