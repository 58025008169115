/* global Foundation, jQuery */

import 'foundation-sites/js/foundation.abide';
import Cookies from 'js-cookie';
import AnalyticsHelper from '../../../assets/js/helpers/analytics/analytics-helper';
import CSRFHelper from '../../../assets/js/helpers/csrf-helper';
import { uploadFormHelper, getUploadFileUrls } from '../../../assets/js/helpers/upload-form-helper';

/**
 * This is a function used to generate a callback which is bound to the window when a form is initialised
 * @param  {function} originalFormSubmitSuccess Currently bound window.formSubmitSuccess
 * @param  {function} originalFormSubmitError   Currently bound window.formSubmitError
 * @return {function}                           Callback called with success response (JSON) by the data
 *                                              layer when a form submission succeeds.
 *                                              Shows success message and resets global variables.
 */
const generateDataLayerFormSubmitSuccess = (originalFormSubmitSuccess, originalFormSubmitError) => (
  (output) => {
    const { form_id: formId } = JSON.parse(output);
    const formElem = document.getElementById(formId);
    const formRedirectUrl = formElem.getAttribute('data-myob-form-redirect-url');
    if (formRedirectUrl) {
      window.location.href = formRedirectUrl;
    } else if (formElem) {
      // Show success
      const successElem = formElem.querySelector('.form__success-response');
      if (successElem) {
        successElem.classList.remove('no-display');
        successElem.classList.add('anim--fade-in');
      }
      // Hide body
      const bodyElem = formElem.querySelector('.form__body');
      if (bodyElem) {
        bodyElem.classList.add('no-display');
      }
    }
    // Reset global vars to original values
    window.formSubmitSuccess = originalFormSubmitSuccess;
    window.formSubmitError = originalFormSubmitError;
  }
);

(function myobFormFactory($, Foundation) {
  class MyobForm extends Foundation.Abide {
    constructor(element, options) {
      const overrideOptions = $.extend(options, MyobForm.defaults);
      super(element, overrideOptions);

      this.crsfHelper = new CSRFHelper();

      Foundation.registerPlugin(this, 'MyobForm');
    }

    _init() {
      this.analyticsHelper = new AnalyticsHelper();

      this.$formId = this.$element[0].id || '';
      this.$formPanel = this.$element.find('.form__panel');
      this.$formBody = this.$element.find('.form__body');
      this.$formSuccess = this.$element.find('.form__success-response');
      this.$formError = this.$element.find('.form__error-response');
      this.$submitButton = this.$element.find('[type=submit]');
      this.$formCookieName = 'form-submission-id';
      this.$formCookieValue = this.$formId;
      this.$formSubmissionType = this.$element.attr('data-myob-form-submission-type');
      this.$formReturnSelf = this.$element.attr('data-myob-form-return-self');
      this.$fileUploadSuccess = this.$element.find('.file-inline-upload-success');

      this.$element.data('height', `${this.$formSuccess.outerHeight()}`);
      if (this.$fileUploadSuccess) {
        uploadFormHelper(this.$submitButton.get(0), this.$fileUploadSuccess.get(0));
      }

      super._init();
    }

    static customTrim(s) {
      return s.trim().replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
    }

    static onInputFocus(ev) {
      $(ev.target.parentNode).addClass('form__group--filled');
    }

    static onInputBlur(ev) {
      if (MyobForm.customTrim(ev.target.value) === '' && !$(ev.target).hasClass('is-invalid')) {
        $(ev.target.parentNode).removeClass('form__group--filled');
      }
    }

    static onSelectBlur(ev) {
      if (ev.target.options[ev.target.selectedIndex].text === '' && !$(ev.target).hasClass('is-invalid')) {
        $(ev.target.parentNode).removeClass('form__group--filled');
      }
    }

    static minRequiredValidator($el) {
      const $parentEl = $el.closest('[data-required-min]');
      if (!$parentEl.attr('required')) return true;

      const min = $parentEl.attr('data-required-min');
      const total = $parentEl.find(':input:checked').length;
      const $inputElement = $parentEl.find(':input');

      if (min > total) {
        $parentEl.find(MyobForm.defaults.formErrorSelector).show();
        $inputElement.each((index, checkbox) => {
          $(checkbox)
            .addClass('is-invalid')
            .attr('data-invalid');
          $(checkbox)
            .parent()
            .addClass('is-invalid');
        });
        return false;
      }

      $inputElement.each((index, checkbox) => {
        $(checkbox)
          .removeClass('is-invalid')
          .removeAttr('data-invalid');
        $(checkbox)
          .parent()
          .removeClass('is-invalid');
      });

      $parentEl.find(MyobForm.defaults.formErrorSelector).hide();

      return true;
    }

    static convertRequestToJSON(data) {
      const unindexedArray = data;
      const indexedArray = {};

      $.map(unindexedArray, (n) => {
        indexedArray[n.name] = n.value;
      });

      indexedArray.pageUrl = window.location.href;
      if (getUploadFileUrls() && getUploadFileUrls().length > 0) {
        indexedArray['upload-file-urls'] = getUploadFileUrls();
      }
      return { data: indexedArray };
    }

    /**
     * Submit synchronously
     */
    submit(formId) {
      // Create cookie with form id for pardot form redirection
      Cookies.set(this.$formCookieName, this.$formCookieValue);
      const formEl = document.getElementById(formId);

      const loc = window.location.href;
      if (this.$formReturnSelf === 'true') {
        formEl.action = `${formEl.action}?success_location=${encodeURIComponent(loc)}`;
      }
      formEl.submit();
    }


    /**
     * Callback which is bound to a callback on the window, triggered when a form errors.
     * This displays error view within.
     * It does not reset the formSubmitSuccess/formSubmitError functions bound to the window
     * as the user may wish to retry to submit the form.
     */
    dataLayerFormSubmitError = () => {
      // Show error
      this.$formError.removeClass('no-display');
      this.$formError.addClass('anim--fade-in');

      // Re-enable submit
      this.$submitButton.removeClass('is-disabled');
      this.$submitButton.removeClass('is-loading');
      this.$submitButton.removeAttr('disabled');
    };

    /**
     * Submit asynchronously
     */
    ajaxPost(formId, token) {
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: this.$element.attr('action'),
          headers: {
            Accept: 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8',
          },
          data: JSON.stringify(MyobForm.convertRequestToJSON(this.$element.serializeArray())),
          beforeSend: (xhr) => {
            if (token) {
              xhr.setRequestHeader('CSRF-Token', token);
            }
          },
        })
          .done((response) => {
            if (response.status === 1) {
              this.analyticsHelper.record('AEM:form:formSuccess', {
                eventInfo: {
                  eventName: 'genericFormSuccess',
                },
                attributes: {
                  _sourceElement: this.$element,
                  formId,
                },
              });
              resolve(response);
            } else {
              this.analyticsHelper.record('AEM:form:formError', {
                eventInfo: {
                  eventName: 'genericFormFail',
                },
                attributes: {
                  _sourceElement: this.$element,
                  formId,
                  errorMessage: this.$formError.text().trim(),
                },
              });
              reject(response);
            }
          })
          .fail((response) => {
            this.analyticsHelper.record('AEM:form:formError', {
              eventInfo: {
                eventName: 'genericFormFail',
              },
              attributes: {
                _sourceElement: this.$element,
                formId,
                errorMessage: this.$formError.text().trim(),
              },
            });

            reject(response);
          });
      });
    }

    _events() {
      super._events();
      const analyticsFormId = AnalyticsHelper.refineFormId(this.$formId);

      if (window.Site.formSuccessId === this.$formId) {
        this.$element.animate({ height: this.$element.data('height') }, 250);
        this.$formBody.addClass('no-display');
        this.$formSuccess.removeClass('no-display').addClass('anim--fade-in');
      }

      const formId = this.$formId;
      let formStartFlag = false;

      this.$inputs.each((index, inputEl) => {
        $(inputEl).on('focus', () => {
          if (!formStartFlag) {
            this.analyticsHelper.record('AEM:form:formStart', {
              eventInfo: {
                eventName: 'genericFormStart',
              },
              attributes: {
                formId: analyticsFormId,
              },
            });
            formStartFlag = true;
          }
        });

        if (MyobForm.customTrim(inputEl.value) !== '') {
          $(inputEl)
            .not('input[type="hidden"]')
            .addClass('form__group--filled');
        }

        if ($(inputEl).is('select')) {
          if (inputEl.options[inputEl.options.selectedIndex].text !== '') {
            $(inputEl)
              .parent()
              .addClass('form__group--filled');
          } else {
            inputEl.addEventListener('blur', MyobForm.onSelectBlur);
          }
        } else {
          inputEl.addEventListener('blur', MyobForm.onInputBlur);
        }

        inputEl.addEventListener('focus', MyobForm.onInputFocus);
      });

      this.$element.on('forminvalid.zf.abide', () => {
        this.$submitButton.addClass('is-disabled is-unsuccessful').attr('disabled', true);

        setTimeout(() => {
          this.$submitButton.removeClass('is-disabled is-unsuccessful').attr('disabled', false);
        }, 2000);
      });

      this.$element.on('submit.zf.abide', (event) => {
        event.preventDefault();

        this.analyticsHelper.record('AEM:form:formSubmit', {
          eventInfo: {
            eventName: 'genericFormSubmit',
          },
          attributes: {
            formId: analyticsFormId,
          },
        });

        if (this.validateForm()) {
          this.$submitButton.addClass('is-disabled is-loading').attr('disabled', true);
          this.$formError.addClass('no-display');

          this.crsfHelper.ajaxGetToken(this.$element.attr('action')).then((token) => {
            if (this.$formSubmissionType === 'ASYNCHRONOUS_SFMC') {
              window.formSubmitSuccess = generateDataLayerFormSubmitSuccess(window.formSubmitSuccess, window.formSubmitError);
              window.formSubmitError = this.dataLayerFormSubmitError;
              this.analyticsHelper.pushData.pushToGoogle({
                elementId: analyticsFormId,
                event: 'Form Submit',
                formData: MyobForm.convertRequestToJSON(this.$element.serializeArray()).data,
              });
            } else if (this.$formSubmissionType !== 'SYNCHRONOUS') {
              this.ajaxPost(analyticsFormId, token).then(
                (value) => {
                  this.$submitButton.removeClass('is-loading is-disabled').addClass('is-successful').attr('disabled', false);

                  setTimeout(() => {
                    this.$submitButton.removeClass('is-successful');

                    if (value.successAction && value.successAction === 'goToUrl' && value.targetURL) {
                      window.location = value.targetURL;
                    } else {
                      this.$element.animate({ height: this.$element.data('height') }, 250);
                      this.$formBody.addClass('no-display');
                      this.$formSuccess.removeClass('no-display').addClass('anim--fade-in');
                    }
                  }, 2000);
                },
                () => {
                  this.$submitButton.removeClass('is-loading').addClass('is-unsuccessful');
                  this.$formError.removeClass('no-display').addClass('anim--fade-in');

                  setTimeout(() => {
                    this.$submitButton.removeClass('is-disabled is-unsuccessful').attr('disabled', false);
                  }, 2000);
                }
              );
            } else {
              this.submit(formId);
            }
          });
        }
      });
    }
  }

  MyobForm.defaults = {
    liveValidate: false,
    validateOnBlur: true,
    labelErrorClass: 'is-invalid',
    inputErrorClass: 'is-invalid',
    formErrorSelector: '.form__error-message',
    formErrorClass: 'is-visible',
    componentClass: 'form',
    validators: {
      min_required: MyobForm.minRequiredValidator,
    },
  };

  Foundation.plugin(MyobForm, 'MyobForm');
}(jQuery, Foundation));
