/**
 * Purpose: A re-usable plugin to enable elements to become filterable.
 */
import {
  isActive, removeActiveState, addActiveState, setItemState, isHidden, show, hide
} from '../../assets/js/helpers/state-helper';
import { registerPluginNames } from '../../assets/js/registrar';

const pluginName = 'filter';

const defaults = {
  animationIn: '',
  animationOut: '',
};

/**
 * Get all filters in the provided element's filter group
 * that contain the provided attribute
 * @param  {HTMLElement} element     DOM Element of a filter group
 * @param  {Object} filterInstance Configuration of the filter instance
 * @param  {NodeList} filterInstance.group DOM Elements of filter groups in this instance
 * @param  {string} attribute        Attribute used to filter
 * @return {HTMLElement[]|null}             Matching elements or null
 */
const getGroupFilters = (element, filterInstance, attribute) => {
  // get all filter(s) based on attribute
  const [parent] = Array.from(filterInstance.group).filter(group => group.contains(element));
  return parent ? Array.from(parent.querySelectorAll(attribute)) : null;
};

/**
 * When checkActive is true, sets active state of any 'All' filter option
 * present in the group if all filter controls in that group are unchecked.
 * Otherwise, remove the active state in all cases.
 * @param  {HTMLElement} element     Filter control last interacted with
 * @param  {Object} filterInstance Filter instance configuration
 * @param  {Boolean} checkActive      Whether to check siblings for active state
 */
const toogleStateAllFilter = (element, filterInstance, active) => {
  const [filterAllSibling] = getGroupFilters(element, filterInstance, '[data-filter-all]');
  if (active) {
    // adding active state to the all filter if every sibling filter has been unchecked
    const filterSiblings = getGroupFilters(element, filterInstance, '[data-filter-control]');
    let numUnchecked = 0;
    filterSiblings.forEach((control) => {
      if (!isActive(control)) {
        numUnchecked += 1;
      }
    });
    if (numUnchecked === filterSiblings.length) {
      addActiveState(filterAllSibling);
      filterAllSibling.disabled = true;
    }
  } else {
    // removing active state from all filter when sibling filter has been checked
    removeActiveState(filterAllSibling);
    filterAllSibling.disabled = false;
  }
};

/**
 * Updates DOM state of filter items, based on results of what should be shown
 * Also shows/hides the noResultsMessage if present
 * @param  {Object} filterInstance Clicked element's filter instance's configuration
 * @param  {NodeList} filterInstance.items Items to be filtered (DOM elements)
 * @param  {Object} filterInstance.settings Default settings for this filter instance
 * @param  {HTMLElement} filterInstance.noResultsMessage No results found message
 * @param  {HTMLElement[]} results Nodes of items to show
 */
const updateDOM = (filterInstance, results) => {
  const { items, settings, noResultsMessage } = filterInstance;
  // Apply show/hide setItemState to each element
  // - setting show or hide based on if they were in the results or not
  Array.from(items).forEach((node) => {
    const action = results.includes(node.getAttribute('data-filter-item-id')) ? 'show' : 'hide';
    setItemState(node, settings.animationIn, settings.animationOut, action);
  });
  // Checks if there is a noResultsMessage
  // - Removes NO_DISPLAY_CLASS if necessary and animates
  // - Class NO_DISPLAY_CLASS should be added to the element in the HTML to avoid a 'jump'
  if (noResultsMessage) {
    const showNoResults = !results.length;
    const noResultsMessageShown = !isHidden(noResultsMessage);
    if (showNoResults) {
      show(noResultsMessage);
    } else if (!noResultsMessageShown) {
      hide(noResultsMessage);
    }
    if (noResultsMessageShown || showNoResults) {
      const action = showNoResults ? 'show' : 'hide';
      setItemState(noResultsMessage, settings.animationIn, settings.animationOut, action);
    }
  }
};

/**
 * Filters items by active keywords in each group
 * - Gets items for each keyword in each group
 * - Intersects results across groups
 * @param  {Object} filterInstance Clicked element's filter instance's configuration
 * @param  {Object[]} filterInstance.groupControls Configuration sets for each group in this filter instance
 * @param  {Object} filterInstance.itemTags Items we are filtering in format: {id: [keywords]}
 */
const filterItems = (filterInstance) => {
  const { groupControls, itemTags } = filterInstance;
  // Counts items by the number of groups they are returned by
  // If that number is the same as the number of active groups - they are returned
  const countOfResults = {};
  let numberOfActiveGroups = 0;
  groupControls.forEach((groupData) => {
    const activeControls = Array.from(groupData.controls).filter(isActive);
    // Only apply filters in this group if at least one option is selected
    if (activeControls.length > 0) {
      numberOfActiveGroups += 1;
      // Get keywords from the active controls (stored in data-filter-control)
      const keywords = Array.from(activeControls).map(activeControl => activeControl.getAttribute('data-filter-control'));
      const results = new Set();
      // Get items that match each keyword
      keywords.forEach(keyword => Object.entries(itemTags)
        .filter(([_id, tags]) => tags.includes(keyword))
        .forEach(([id]) => results.add(id)));
      // Increment the count for this result - used to intersect
      results.forEach((result) => {
        if (!(result in countOfResults)) countOfResults[result] = 0;
        countOfResults[result] += 1;
      });
    }
  });
  // Get all items that appeared in every result, or all items
  const intersectionOfResults = numberOfActiveGroups
    ? Object.entries(countOfResults)
      .filter(([_id, occurrences]) => occurrences === numberOfActiveGroups)
      .map(([id]) => id)
    : Object.keys(itemTags);
  // Update DOM to reflect results
  updateDOM(filterInstance, intersectionOfResults);
};

/**
 * Handle click event on any filter control that is not an 'All' option
 * - Sets the active state on filter controls in the group
 * - Call filterItems
 * @param  {MouseEvent} event       Click event
 * @param  {Object} filterInstance Clicked element's filter instance's configuration
 * @param  {NodeList} filterInstance.all 'All' option nodes
 */
const singleFilterClickHandler = (event, filterInstance) => {
  event.preventDefault();
  const element = event.currentTarget;

  // Set active state of clicked filter and that group's 'All' filter (if present)
  if (isActive(element)) {
    removeActiveState(element);
    if (filterInstance.all.length > 0) toogleStateAllFilter(element, filterInstance, true);
  } else {
    addActiveState(element);
    if (filterInstance.all.length > 0) toogleStateAllFilter(element, filterInstance, false);
  }
  filterItems(filterInstance);
};

/**
 * Handles click event on an 'All' option
 * - Sets appropriate active state
 * - Calls filterItems
 * @param  {MouseEvent} event       Click event
 * @param  {Object} filterInstance Current filter instance's configuration
 */
const allFilterClickHandler = (event, filterInstance) => {
  event.preventDefault();
  const element = event.currentTarget;
  const filterSiblings = getGroupFilters(element, filterInstance, '[data-filter-control]');

  if (!isActive(element)) {
    addActiveState(element);
    filterSiblings.forEach(control => removeActiveState(control));
    element.disabled = true;
    filterItems(filterInstance);
  }
};

/**
 * Adds event listeners to controls in each filter instance
 * @param {Object[]} filterInstances Filter instances' configurations
 */
const addEventListeners = (filterInstances) => {
  Array.from(filterInstances).forEach((filterGroup) => {
    Array.from(filterGroup.controls).forEach((control) => {
      control.addEventListener('click', event => singleFilterClickHandler(event, filterGroup));
    });

    Array.from(filterGroup.all).forEach((control) => {
      control.addEventListener('click', event => allFilterClickHandler(event, filterGroup));
    });
  });
};

/**
 * Initialise filter plugin for all elements
 * @param  {NodeList} elements HTMLElements to initialise filter plugin on
 */
const init = (elements) => {
  const dataFilters = Array.from(elements).map((element) => {
    // Create a dictionary of id: keywords for each item
    const itemTags = {};
    const itemNodeList = element.querySelectorAll('[data-filter-tags]');
    Array.from(itemNodeList).forEach((itemNode, index) => {
      itemTags[index] = itemNode.getAttribute('data-filter-tags').split(',');
      itemNode.setAttribute('data-filter-item-id', index);
    });
    // Get groups controls and name
    const group = element.querySelectorAll('[data-filter-group]');
    const groupControls = Array.from(group).map((groupElement) => {
      let controls = groupElement.querySelectorAll('[data-filter-control]');
      if (!controls.length) controls = groupElement.hasAttribute('data-filter-control') ? [groupElement] : [];
      return {
        groupName: groupElement.getAttribute('data-filter-group'),
        controls,
      };
    });
    const settings = {
      settings: {
        animationIn: element.getAttribute('data-filter-animation-in') || defaults.animationIn,
        animationOut: element.getAttribute('data-filter-animation-out') || defaults.animationOut,
      },
      group,
      groupControls,
      all: element.querySelectorAll('[data-filter-all]'),
      controls: element.querySelectorAll('[data-filter-control]'),
      items: element.querySelectorAll('[data-filter-tags]'),
      noResultsMessage: element.querySelector('.no-records-found'),
      itemTags,
    };
    filterItems(settings);
    return settings;
  });
  addEventListeners(dataFilters);
};

export default init;
export { defaults };

registerPluginNames(init, pluginName);
