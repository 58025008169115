/* global Foundation, jQuery */

import 'foundation-sites/js/foundation.sticky';
import 'foundation-sites/js/foundation.util.triggers';
import AnalyticsHelper from '../../../assets/js/helpers/analytics/analytics-helper';

(function myobInPagenavigationFactory($, Foundation) {
  class MyobInPageNavigation extends Foundation.Sticky {
    /* eslint-disable no-underscore-dangle */
    constructor(element, options) {
      const overrideOptions = $.extend({}, MyobInPageNavigation.defaults, element.data(), options);

      super(element, overrideOptions);
      this.options = overrideOptions;

      Foundation.registerPlugin(this, 'MyobInPageNavigation');
    }

    _init() {
      super._init();

      this.$trigger = this.$element.find('button.in-page-navigation__trigger');
      this.$triggerCopyUpdateArea = this.$element.find('.in-page-navigation__trigger > span');
      this.triggerInitialText = this.$triggerCopyUpdateArea.text();
      this.triggerUpdateText = this.triggerInitialText;
      this.$menu = this.$trigger.siblings('ul.in-page-navigation__list');
      this.menuCountItems = this.$menu.children().length;
      this.screenIsMobile = MyobInPageNavigation.checkIsMobile();
      this.isOpen = true;

      this.setHeight();

      this.analyticsHelper = new AnalyticsHelper();
    }

    _events() {
      super._events();

      const that = this;

      if (this.isStuck && this.isMobile()) {
        this.slideUpMenu();
      }

      this.$trigger.on('click', () => {
        if (this.isMobile()) {
          if (this.$menu.length && this.$menu.is(':hidden')) {
            that.slideDownMenu();
          } else {
            that.slideUpMenu();
          }
        }
      });

      this.$element.on('sticky.zf.stuckto:top', () => {
        if (this.isMobile()) {
          that.slideUpMenu();
        }
      });

      this.$element.on('sticky.zf.unstuckfrom:top', () => {
        if (this.isMobile()) {
          that.slideDownMenu();
          that.$trigger.removeClass('is-active');
        }
      });

      this.$menu.on('click.zf.magellan', (event) => {
        if (this.isMobile()) {
          that.slideUpMenu();
        }

        const itemIndex = $(event.target)
          .parent()
          .index() + 1;

        that.analyticsHelper.record('AEM:navigationSub:navigationItemClick', {
          eventInfo: {
            eventAction: 'subNavItemClicked',
            eventName: $(event.target)
              .text()
              .trim(),
            timeStamp: Date.now(), // unix timestamp
          },
          attributes: {
            targetUrl: $(event.target).attr('href'),
            numOfItems: this.menuCountItems,
            subNavItemIndex: itemIndex,
            _sourceElement: event.target,
          },
        });
      });

      this.$menu.on('update.zf.magellan', (ele, target) => {
        if (target[0]) {
          that.triggerUpdateText = target[0].innerText;
        }

        if (this.isOpen) {
          that.updateTriggerText(this.triggerInitialText);
        } else {
          that.updateTriggerText(this.triggerUpdateText);
        }
      });

      $(window).on('changed.zf.mediaquery', () => {
        this.screenIsMobile = MyobInPageNavigation.checkIsMobile();
        this.setHeight();

        if (this.screenIsMobile && this.isStuck) {
          this.$menu.hide();
        } else {
          this.$menu.show();
        }
      });
    }

    static checkIsMobile() {
      if (Foundation.MediaQuery.atLeast('large')) {
        return false;
      }
      return true;
    }

    isMobile() {
      return this.screenIsMobile;
    }

    slideUpMenu() {
      this.isOpen = false;

      this.$menu.removeClass('is-open').slideUp(this.options.slideSpeed);
      this.$trigger.removeClass('is-active');
      this.updateTriggerText(this.triggerUpdateText);
      this._setSizes();
    }

    slideDownMenu() {
      this.isOpen = true;

      this.$menu.addClass('is-open').slideDown(this.options.slideSpeed);
      this.$trigger.addClass('is-active');
      this.updateTriggerText(this.triggerInitialText);
      this._setSizes();
    }

    updateTriggerText(triggerText) {
      this.$triggerCopyUpdateArea.text(triggerText);
    }

    setHeight() {
      if (this.isMobile() && !this.mobileMenuHeight) {
        this.mobileMenuHeight = parseInt(this.$element.outerHeight(), 10);
      } else if (!this.isMobile() && !this.desktopMenuHeight) {
        this.desktopMenuHeight = parseInt(this.$element.outerHeight(), 10);
      }
    }

    // Extending Foundation function to set our own height on mobile
    // TODO: Combine best of Foundation Magellan, Accordion Menu and Sticky into a MYOB plugin
    _setSizes(cb) {
      const newElemWidth = this.$container[0].getBoundingClientRect().width;
      const comp = window.getComputedStyle(this.$container[0]);
      const pdngl = parseInt(comp['padding-left'], 10);
      const pdngr = parseInt(comp['padding-right'], 10);
      let newContainerHeight = this.$element[0].getBoundingClientRect().height || this.containerHeight;

      this.canStick = Foundation.MediaQuery.is(this.options.stickyOn);
      if (!this.canStick) {
        if (cb && typeof cb === 'function') {
          cb();
        }
      }

      if (this.$anchor && this.$anchor.length) {
        this.anchorHeight = this.$anchor[0].getBoundingClientRect().height;
      } else {
        this._parsePoints();
      }

      this.$element.css({
        'max-width': `${newElemWidth - pdngl - pdngr}px`,
      });

      if (this.$element.css('display') === 'none') {
        newContainerHeight = 0;
      }

      if (this.isMobile()) {
        newContainerHeight = this.mobileMenuHeight;
      }
      this.containerHeight = newContainerHeight;
      this.$container.css({
        height: newContainerHeight,
      });
      this.elemHeight = newContainerHeight;

      if (!this.isStuck) {
        if (this.$element.hasClass('is-at-bottom')) {
          const anchorPt = (this.points ? this.points[1] - this.$container.offset().top : this.anchorHeight) - this.elemHeight;
          this.$element.css('top', anchorPt);
        }
      }

      this._setBreakPoints(newContainerHeight, () => {
        if (cb && typeof cb === 'function') {
          cb();
        }
      });
    }
    /* eslint-enable no-underscore-dangle */
  }

  MyobInPageNavigation.defaults = {
    stickTo: 'top',
    anchor: '',
    topAnchor: '',
    btmAnchor: '',
    marginTop: 0,
    stickyOn: 'small',
    stickyClass: 'in-page-navigation__menu',
    containerClass: 'in-page-navigation__sticky-container',
    checkEvery: -1,
    slideSpeed: 250,
  };

  Foundation.plugin(MyobInPageNavigation, 'MyobInPageNavigation');
}(jQuery, Foundation));
