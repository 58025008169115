import { registerPluginNames } from '../../assets/js/registrar';

import {
  isActive,
} from '../../assets/js/helpers/state-helper';

import {
  show, hide, makeToggleInactive, makeToggleActive
} from './display-toggle.helpers';

/**
 * Check if toggles set to close on mobile should close (i.e. we are on mobile)
 * @param  {Object[]} elementData
 * @param  {Boolean} [animate=true] Should the transition be animated
 */
const hideOrShowForResponsiveToggles = (elementData, animate = true) => window.Site.debounce(() => {
  const mediumScreenWidth = window.Site.minSmallScreen || 767;
  const responsiveToggles = elementData.filter(
    ({ responsive }) => responsive
  );
  if (window.innerWidth <= mediumScreenWidth) {
    responsiveToggles.forEach(({
      toggle, items, animIn, animOut
    }) => {
      if (animate) {
        makeToggleInactive({
          toggle, items, animIn, animOut
        });
      } else {
        makeToggleInactive({ toggle, items });
      }
    });
  } else {
    responsiveToggles.forEach(({
      toggle, items, animIn, animOut
    }) => {
      if (animate) {
        makeToggleActive({
          toggle, items, animIn, animOut
        });
      } else {
        makeToggleActive({ toggle, items });
      }
    });
  }
}, 250);

const addEventListeners = (elementData) => {
  elementData.forEach(({
    toggle, items, animIn, animOut
  }) => {
    toggle.addEventListener('click', () => {
      if (isActive(toggle)) {
        makeToggleInactive({
          toggle, items, animIn, animOut
        });
      } else {
        makeToggleActive({
          toggle, items, animIn, animOut
        });
      }
    });
  });
};

const init = (elements) => {
  const elementData = Array.from(elements).map(element => ({
    toggle: element,
    animIn: element.dataset.animIn || (element.dataset.anim ? `${element.dataset.anim}-in` : null),
    animOut: element.dataset.animOut || (element.dataset.anim ? `${element.dataset.anim}-out` : null),
    items: Array.from(document.querySelectorAll(`[data-display-toggle="${element.dataset.displayToggleTarget}"]`)),
    responsive: element.hasAttribute('data-responsive') && element.dataset.responsive !== 'false',
  }));

  addEventListeners(elementData);

  // Check toggles, any marked as active ensure targets are shown and v.v. - do not animate
  elementData.forEach(({ toggle, items }) => {
    if (isActive(toggle)) {
      items.forEach(item => show(item, null, null));
    } else {
      items.forEach(item => hide(item, null, null));
    }
  });

  // Resize for mobile viewports - with no animation for initialisation
  hideOrShowForResponsiveToggles(elementData, false)();
  window.addEventListener('resize', hideOrShowForResponsiveToggles(elementData));
};

export default init;
export { addEventListeners, hideOrShowForResponsiveToggles };
registerPluginNames(init, 'display-toggle');
