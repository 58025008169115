/* global Foundation, jQuery */

import 'foundation-sites/js/foundation.util.keyboard';
import 'foundation-sites/js/foundation.accordion';
import 'foundation-sites/js/foundation.util.motion';
import AnalyticsHelper from '../../../assets/js/helpers/analytics/analytics-helper';

(function myobAccordionFactory($, Foundation) {
  class MyobAccordion extends Foundation.Accordion {
    constructor(element, options) {
      const overrideOptions = $.extend({}, MyobAccordion.defaults, element.data(), options);

      super(element, overrideOptions);
      this.options = overrideOptions;

      const that = this;

      Foundation.registerPlugin(this, 'MyobAccordion');

      // Get IDs of all accordion items
      this.accordionItemIds = [];

      this.$element.find('.accordion__item a').each(function getAccordionId() {
        that.accordionItemIds.push($(this).attr('id'));
      });

      this.analyticsHelper = new AnalyticsHelper();
    }

    _events() {
      super._events();

      const that = this;

      // Accordion expanded
      this.$element.on('down.zf.accordion', (ele, target) => {
        const expandedAccordionItemId = $(target)[0].previousElementSibling.id;
        const expandedAccordionItemIdIndex = that.accordionItemIds.indexOf(expandedAccordionItemId);
        let expandedAccordionItemHeading;

        if (expandedAccordionItemIdIndex > -1) {
          expandedAccordionItemHeading = that.$element.find(`#${expandedAccordionItemId}`).text();
        }

        that.analyticsHelper.record('AEM:accordion:accordionExpand', {
          eventInfo: {
            eventName: 'accordionExpand',
          },
          attributes: {
            accordionExpandItemIndex: (that.accordionItemIds.indexOf(expandedAccordionItemId)) + 1,
            accordionExpandItemHeading: expandedAccordionItemHeading,
            componentClass: that.options.componentClass,
            numOfItems: that.$element.find('.accordion__item').length,
            _sourceElement: $(target)[0].previousElementSibling,
          },
        });
      });

      // Accordion collapsed
      that.$element.on('up.zf.accordion', (ele, target) => {
        const collapsedAccordionItemId = $(target)[0].previousElementSibling.id;
        const collapsedAccordionItemIdIndex = that.accordionItemIds.indexOf(collapsedAccordionItemId);
        let collapsedAccordionItemHeading;
        if (collapsedAccordionItemIdIndex > -1) {
          collapsedAccordionItemHeading = that.$element.find(`#${collapsedAccordionItemId}`).text();
        }

        that.analyticsHelper.record('AEM:accordion:accordionCollapse', {
          eventInfo: {
            eventName: 'accordionCollapse',
          },
          attributes: {
            accordionCollapseItemIndex: (that.accordionItemIds.indexOf(collapsedAccordionItemId)) + 1,
            accordionCollapseItemHeading: collapsedAccordionItemHeading,
            componentClass: that.options.componentClass,
            numOfItems: that.$element.find('.accordion__item').length,
            _sourceElement: $(target)[0].previousElementSibling,
          },
        });
      });
    }
  }

  MyobAccordion.defaults = {
    componentClass: 'accordion',
  };

  Foundation.plugin(MyobAccordion, 'MyobAccordion');
}(jQuery, Foundation));
