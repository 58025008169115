class Accordion {
  constructor(event, data) {
    this.event = event;
    this.data = data;
  }

  googleFormat() {
    const googleFormattedData = {};
    switch (this.event) {
      case 'accordionExpand': {
        googleFormattedData.event = this.data.eventInfo.eventName;
        googleFormattedData.itemIndex = this.data.attributes.accordionExpandItemIndex;
        googleFormattedData.itemText = this.data.attributes.accordionExpandItemHeading;
        googleFormattedData.itemCount = this.data.attributes.numOfItems;

        break;
      }

      case 'accordionCollapse': {
        googleFormattedData.event = this.data.eventInfo.eventName;
        googleFormattedData.itemIndex = this.data.attributes.accordionCollapseItemIndex;
        googleFormattedData.itemText = this.data.attributes.accordionCollapseItemHeading;
        googleFormattedData.itemCount = this.data.attributes.numOfItems;

        break;
      }

      default:
        throw Error(`Event ${this.event} is not registered`);
    }
    return googleFormattedData;
  }
}

export default Accordion;
